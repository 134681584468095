<template>
    <div>
        <CCard class="zw-page" v-if="!loading">
            <CCardBody>
                <zw-table ref="table"
                          tableName="crm"
                          base-table="offering"
                          columnsPrefix="common.column.crm."
                          :items="myProvider"
                          :fields="fields"
                          :filter="filter"
                          :selected="selected"
                          :additional-options="{start: dateStart,end: dateEnd}"
                          title="common.title.crm_kva"
                >
                    <template v-slot:top-actions>
                        <b-form inline class="mb-2">
                            <font-awesome-icon
                                role="button"
                                icon="chevron-left"
                                @click="prevMonth()"
                            />&nbsp;
                            <b-form-select
                                v-model="month"
                                :options="monthsList()"
                                class="mr-2"
                                @change="monthChanged"
                            ></b-form-select>
                            <font-awesome-icon
                                role="button"
                                icon="chevron-right"
                                @click="nextMonth()"
                            />&nbsp;
                        </b-form>
                    </template>

                    <template #cell(contact_person)="row">
                        <div>{{ row.item.contact_person.vorname }} {{ row.item.contact_person.name }}</div>
                        <div>{{ row.item.contact_person.telefon }}</div>
                        <div>{{ row.item.contact_person.email }}</div>
                    </template>

                    <template #cell(todos)="row">
                        <div style="min-width: 800px">
                            <todos :todos="row.item.todos" :callback="refreshTable"></todos>
                        </div>
                    </template>

                    <template #cell(total)="row">
                        <b-dropdown class="mb-1"
                                    left
                                    variant="primary"
                                    size="sm"
                        >
                            <template #button-content>
                                {{ $t('common.label.actions') }}
                            </template>

                            <b-dropdown-item
                                @click="$root.$children[0].openModal('todo-modal', {kva_id: row.item.id, customer_id: row.item.customer_id}, refreshTable)">
                                {{ $t('common.button.add_todo') }}
                            </b-dropdown-item>
                        </b-dropdown>
                    </template>
                </zw-table>
            </CCardBody>
        </CCard>
    </div>
</template>

<script>
import {mapGetters} from 'vuex'
import commonTable from '@/bundles/erika_common_bundle/mixins/common-table'
import tableDateFilter from '@/bundles/erika_common_bundle/mixins/table-date-filter'

export default {
    name: 'Crm',
    mixins: [commonTable, tableDateFilter],
    data() {
        return {
            loading: true,
            type: null,
            crmStatuses: {
                crm_crm_statuses: null,
                crm_aftersale_statuses: null,
            },
        }
    },
    methods: {
        ...mapGetters('Crm', ['getOfferingsTable']),
        ...mapGetters('Settings', ['getCrmStatusMapIds']),
        data() {
            return {
                month: null,
                dateStart: null,
                dateEnd: null,
                saveFilters: true,
            }
        },
        shown() {
            this.loading = true
            const settingsCrmStatusMapIds = this.$store.dispatch('Settings/fetchCrmStatusMapIds');
            Promise.all([settingsCrmStatusMapIds]).then(() => {
                this.loading = false
                this.crmStatuses = this.getCrmStatusMapIds();
            });
        },
        myProvider(ctx) {
            return this.commonProviderFetch(ctx, 'Crm/fetchOfferings', 'getOfferingsTable');
        },
        ctxAdditionalParams() {
            if (this.type == 'crm') {
                return {
                    'status':  this.crmStatuses.crm_crm_statuses
                }
            }
            if (this.type == 'aftersale') {
                return {
                    'status':  this.crmStatuses.crm_aftersale_statuses
                }
            }
            return {}
        },
    },
    mounted() {
        this.shown();
        this.type = this.$route.meta.type
    }
}
</script>